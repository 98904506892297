*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #141616;
  background-color: #e6e9ea;
  font-size: 28px;
}

.titleH1 {
  font-size: 8rem;
  margin-bottom: 1rem;
}


.Paragraf{
  font-size: 2rem;
  margin-bottom: 1rem;
}
.full-screen-section {
  height: 100vh;
  text-align: center;
  padding: 1rem;
  margin-bottom: 2rem;
  position: relative;
}

.hero {
  
  transform: translateY(0px);
  max-width: 50%;
}

#header {
  position: fixed;
  width: 50%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 40px;
  transform: translateY(0);
  z-index: 10;
  will-change: transform;
}

#header {
  --value: min(var(--scroll), 50) * 50 / 50;
  width: calc(50% + 1.6% * var(--value));
  height: 4rem;
  max-width: 100vw;
}

.top-section .left,
.top-section .right {
  flex-basis: 0;
  flex-grow: 1;
  padding: 1rem;
  padding-left: 3rem;
}

.top-section {
  display: flex;
  text-align: start;
  padding: 0;
}

.top-section .left {
  background-color: rgb(248, 250, 249, var(--background-opacity));
}

.top-section .right {
  background: linear-gradient(
    210.65deg,
    rgb(235, 245, 255, var(--background-opacity)) 0%,
    rgb(242, 248, 255, var(--background-opacity)) 100%
  );
}

.top-section .right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.imgs > .top-section-img {
  /* (oldVal - oldMin) * newRange / oldRange + newMin */
  --value: min(var(--scroll), 30) * 50 / 30;
  width: calc(50vw + 1vw * var(--value));
  transform: translateY(calc(50% - 1% * var(--value)));
}

@media (width <= 1000px) {
  .top-section {
    /* display: none; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  #header {
    width: 100%;
  }

  .top-section .left {
    text-align: center;
    
  }

  body {
    font-size: 1rem;
  }

  .titleH1 {
    font-size: 3rem;
  }



  .imgs > .top-section-img {
    --value: min(var(--scroll), 30) * 1 / 30;
    width: 100%;
    transform: translateY(calc(1% - 1% * var(--value)));
  }
  
  .full-screen-section {
    height: 100%;
    text-align: center;
    padding: 1rem;
    margin-bottom: 2rem;
    position: relative;
  }
}

:root {
  --scroll: 0;
}

.top-section {
  position: sticky;
  top: 0;
  /* (oldVal - oldMin) * newRange / oldRange + newMin */
  translate: 0 calc(-1% * (max(var(--scroll), 25) - 25) * 100 / 75);
  --background-opacity: calc(100% - 1% * min(var(--scroll), 30) * 100 / 30);
}

.first-main-section {
  padding-top: 10vh;
}

.imgs > * {
  position: fixed;
  width: 100vw;
  bottom: 0;
  z-index: 2;
  translate: 0 100%;
  transition: translate 250ms ease-in-out;
}

.imgs > .show {
  translate: 0 0;
}

[data-img-to-show] {
  position: absolute;
  top: 20%;
}
